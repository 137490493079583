import React from 'react';
import {withStyles} from '@material-ui/core';
import styles from 'components/commonStyles';
import PropTypes from 'prop-types';
import {graphql, StaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash/get';
import classnames from 'classnames';
import {navigate} from '@reach/router';

const Case3 = ({classes}) => (
  <StaticQuery
    query={graphql`
      query {
        image: allFile(
          filter: { relativePath: { eq: "other/api-chooser/3.png" } }
        ) {
          edges {
            node {
              id
              childImageSharp {
                id
                fluid(
                  srcSetBreakpoints: [200, 340, 520, 800, 890]
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const img = get(data, 'image.edges[0].node.childImageSharp.fluid', {});

      const bigColor = classnames(classes.textColor, classes.bigString);
      const smallColor = classnames(classes.textColor, classes.smallString);
      const linkColor = classnames(classes.textColor, classes.link);

      const clickButton = () => {
        navigate('#simple-articles-consumer');
      };

      return (
        <div className={classes.case} id="thin-client-cash-register">
          <div className={classes.head}>
            <h4 className={classes.h4Absolute}>
              Use Case #3. Thin client cash register
            </h4>
          </div>
          <p className={classes.textHead} style={{marginBottom: 30}}>
            You have a POS working in a browser. Or very simple online POS
            inside mobile device. And you need a storage for your data and
            processing for your receipts.
          </p>
          <Img fluid={img} style={{maxWidth: 669}} />
          <p style={{marginTop: 60, marginBottom: 20}}>
            To fetch taxes, categories, products and commodities see{" "}
            <span className={linkColor} onClick={clickButton}>
              Use Case #1. Simple Articles consumer
            </span>
            .
          </p>
          <p style={{marginBottom: 30}}>
            Fiscal operations are presented in this table:
          </p>
          <div className={classes.flex30} style={{marginBottom: 20}}>
            <h5 className={classes.bigString}>JSON</h5>
            <h5 className={classes.smallString}>Java</h5>
            <h5 className={classes.bigString}>Why?</h5>
          </div>
          <div className={classes.tableColor}>
            <div className={classes.flex30}>
              <p className={classes.bigString}>
                POST /registrations/
                <br />
                {'{cashRegisterName}/balance'}
              </p>
              <p className={classes.smallString}>setBalance()</p>
              <p className={classes.bigString}>to withdraw and deposit</p>
            </div>
            <div className={classes.flex30}>
              <p className={classes.bigString}>GET /version</p>
              <p className={classes.smallString}>getThisVersion()</p>
              <p className={classes.bigString}>
                to make sure thin client has the top latest version of articles
              </p>
            </div>
            <div className={classes.flex30}>
              <p className={classes.bigString}>
                {'GET /registrations/{cashRegister'}
                <br />
                {'Name}/version'}
              </p>
              <p className={classes.smallString}> </p>
              <p className={classes.bigString}>
                to make sure this cashier does not get in conflict with another
                cashier
              </p>
            </div>
            <div className={classes.flex30}>
              <p className={classes.bigString}>POST /sales</p>
              <p className={classes.smallString}>create(), sample</p>
              <p className={classes.bigString}>to make a sale</p>
            </div>
            <div className={classes.flex30}>
              <p className={classes.bigString}>
                {'GET /sales/{identifier}/receipt'}
              </p>
              <p className={classes.smallString}> </p>
              <p className={classes.bigString}>to get a receipt</p>
            </div>
            <div className={classes.flex30}>
              <p className={classes.bigString}>
                {'POST /sales/{identifier}/receipt'}
              </p>
              <p className={classes.smallString}> </p>
              <p className={classes.bigString}>
                to confirm the receipt is printed
              </p>
            </div>
            <div className={classes.flex30}>
              <p className={classes.bigString}>POST /days/current</p>
              <p className={classes.smallString}> </p>
              <p className={classes.bigString}>to close the day</p>
            </div>
            <div className={classes.flex30}>
              <p className={classes.bigString}>GET /days</p>
              <p className={classes.smallString}> </p>
              <p className={classes.bigString}>to get days</p>
            </div>
            <div className={classes.flex30}>
              <p className={classes.bigString}>{'GET /days/{dayId}/report'}</p>
              <p className={classes.smallString}> </p>
              <p className={classes.bigString}>to get an X-/Z-report</p>
            </div>
          </div>
        </div>
      );
    }}
  />
);

Case3.propTypes = {
  classes: PropTypes.shape({
    case: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired,
    h4Absolute: PropTypes.string.isRequired,
    flex30: PropTypes.string.isRequired,
    bigString: PropTypes.string.isRequired,
    smallString: PropTypes.string.isRequired,
    tableColor: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, {withTheme: true})(Case3);
